import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Container, CssBaseline, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAlert } from '../AlertContext';

const StyledContainer = styled(Container)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100%',
    background: 'linear-gradient(135deg, #f5f5f5, #3f2b96)',
    backgroundSize: 'cover',
});

const FormBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '2rem',
    borderRadius: '12px',
    backgroundColor: '#ffffff',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    width: '100%',
    maxWidth: '400px',
});

const BrandingTypography = styled(Typography)({
    color: '#333',
    fontWeight: 700,
    textAlign: 'center',
    position: 'relative',
    zIndex: 1,
});

const BetaLabel = styled(Typography)({
    fontSize: '0.75rem',
    color: '#ffffff',
    position: 'absolute',
    bottom: '12px',
    right: '-50px',
    backgroundColor: '#ff6f61',
    borderRadius: '12px',
    padding: '9px 8px',
    zIndex: 1,
});

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const { setAlert } = useAlert();

    const handleLogin = async (event) => {
        event.preventDefault();
        setAlert({ message: '', severity: '' });
        setLoading(true);

        try {
            const response = await fetch('https://api.inindia.cloud/api/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });

            const data = await response.json();
            setLoading(false);

            if (response.ok) {
                sessionStorage.setItem('inindia_analt_7y254', data.token);
                setAlert({ message: 'Login successful!', severity: 'success' });
                console.log('Login successful!');
            } else {
                setAlert({ message: data.message || 'Login failed! Please check your credentials.', severity: 'error' });
                console.error('Login failed!');
            }
        } catch (error) {
            setLoading(false);
            setAlert({ message: 'An error occurred. Please try again later.', severity: 'error' });
            console.error('Error during login:', error);
        }
    };

    return (
        <StyledContainer component="main">
            <CssBaseline />
            <FormBox>
                <BrandingTypography variant="h4" component="div" sx={{ mb: 2 }}>
                    ININDIA Analytics
                    <BetaLabel>(beta)</BetaLabel>
                </BrandingTypography>
                <Typography variant="subtitle1" component="div" sx={{ mb: 3, color: '#666', textAlign: 'center' }}>
                    Welcome back! Please sign in to continue.
                </Typography>
                <Box component="form" sx={{ width: '100%' }} onSubmit={handleLogin}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="Email Address"
                        autoComplete="email"
                        autoFocus
                        variant="outlined"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '8px',
                            },
                        }}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="Password"
                        type="password"
                        autoComplete="current-password"
                        variant="outlined"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '8px',
                            },
                        }}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <input
                                type="checkbox"
                                id="rememberMe"
                                style={{ marginRight: '8px' }}
                                aria-label="Remember me"
                            />
                            <label htmlFor="rememberMe" style={{ fontSize: '0.875rem' }}>Remember me</label>
                        </Box>
                        <Link href="#" variant="body2" sx={{ fontSize: '0.875rem' }}>
                            Forgot password?
                        </Link>
                    </Box>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={loading} // Disable button while loading
                        sx={{
                            mt: 2,
                            mb: 1,
                            ':hover': {
                                backgroundColor: '#0056b3',
                            },
                        }}
                    >
                        {loading ? 'Signing In...' : 'Sign In'}
                    </Button>
                </Box>
            </FormBox>
        </StyledContainer>
    );
};

export default Login;
