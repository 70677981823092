import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import { AppBar, Box, CssBaseline, Toolbar, Typography, IconButton, Drawer, Divider } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Sidebar from './components/Sidebar';
import Overview from './pages/Overview';
import Reports from './pages/Reports';
import Settings from './pages/Settings';
import Login from './pages/Login';
import Home from './pages/Home';  // Add a separate Home page
import ExcelUpload from './pages/ExcelDataReport'
import { AlertProvider } from './AlertContext';

const drawerWidth = 240;

function App() {
  return (
    <Router>
      <AlertProvider>
        <CssBaseline />
        {/* Route definition */}
        <Routes>
          <Route path="/login" element={<LoginFullScreen />} />
          <Route path="/" element={<HomeFullScreen />} />
          <Route path="/upload" element={<ExcelUpload />} />
          <Route path="/overview" element={<MainLayout><Overview /></MainLayout>} />
          <Route path="/reports" element={<MainLayout><Reports /></MainLayout>} />
          <Route path="/settings" element={<MainLayout><Settings /></MainLayout>} />
          <Route path="*" element={<Navigate to="/" />} /> {/* Redirect to home for any unknown routes */}
        </Routes>
      </AlertProvider>
    </Router>
  );
}

/** Full-screen Login Page */
function LoginFullScreen() {
  return (
    <Box sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Login /> {/* Ensure the login page fills the entire screen */}
    </Box>
  );
}

/** Full-screen Home Page */
function HomeFullScreen() {
  return (
    <Box sx={{ height: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Home /> {/* Full-screen Home page */}
    </Box>
  );
}

/** Main Layout Component for other pages */
function MainLayout({ children }) {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLinkClick = () => {
    setMobileOpen(false); // Close the drawer on link click
  };

  return (
    <>
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            ININDIA Analytics
          </Typography>
        </Toolbar>
      </AppBar>

      <Box sx={{ display: 'flex' }}>
        {/* Sidebar Drawer for larger screens */}
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': { 
              width: drawerWidth, 
              boxSizing: 'border-box',
              zIndex: 1300,
              top: '64px', // Matches AppBar height for consistent placement
            },
          }}
        >
          <Toolbar />
          <Divider />
          <Sidebar onLinkClick={handleLinkClick} /> {/* Pass the click handler */}
        </Drawer>

        {/* Responsive Mobile Drawer */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              top: '64px',
              height: 'calc(100% - 64px)',
              zIndex: 1300,
            },
          }}
        >
          <Toolbar />
          <Sidebar onLinkClick={handleLinkClick} /> {/* Pass the click handler */}
        </Drawer>

        {/* Main Content */}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            pt: { xs: 10, sm: 8 }, // Ensure padding to prevent overlap
            width: { sm: `calc(100% - ${drawerWidth}px)` },
          }}
        >
          <Toolbar />
          {children}
        </Box>
      </Box>
    </>
  );
}

export default App;
