import React from 'react';
import { Typography, Paper, Box } from '@mui/material';

function Reports() {
  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Reports
      </Typography>
      <Paper elevation={3} sx={{ p: 2 }}>
        <Typography>Report content goes here.</Typography>
      </Paper>
    </Box>
  );
}

export default Reports;
