import React, { useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import './HomePage.css';
import PopupMenu from '../components/PopupMenu';
import HeroSection from '../components/HeroSection';

// Function to generate the next data point based on the current time
const generateNextDataPoint = (index) => {
  const hour = new Date();
  hour.setHours(hour.getHours() - (48 - index));

  const uv = Math.floor(2500 + Math.sin((index / 12) * 2 * Math.PI + Math.random() * 0.5) * 1000);
  const pv = Math.floor(2500 + Math.cos((index / 12) * 2 * Math.PI + Math.random() * 0.5) * 1000);

  return {
    name: hour.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
    uv,
    pv,
  };
};

function HomePage() {
  const [data, setData] = useState(Array.from({ length: 48 }, (_, i) => generateNextDataPoint(i)));
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setData((prevData) => {
        const newData = [...prevData.slice(1)];
        const nextIndex = prevData.length;
        newData.push(generateNextDataPoint(nextIndex));
        return newData;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);


  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };


  const Footer = () => (
    <footer className="footer">
      <p>© 2024 ININDIA Analytics. All rights reserved.</p>
    </footer>
  );
  
  
  return (
    <div className="homepage">
        <button className="menu-icon" onClick={() => setIsMenuOpen(true)}>
      ☰
    </button>
    <PopupMenu isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
    <HeroSection />
      {/* <section className="hero-section">
        <h1 className="hero-title">Welcome to ININDIA Analytics</h1>
        <p className="hero-subtitle">Your journey starts with data-driven insights.</p>
        <div className="scroll-down" onClick={() => scrollToSection('chart-section')}>
          Scroll Down
        </div>

      </section> */}

      {/* Animated Chart Section */}
      <section className="chart-section" id="chart-section">
        <h2 className="section-title-demo">Live Data Overview</h2>
        <div className="chart-container">
          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="pv"
                stroke="#8884d8"
                strokeWidth={2}
                dot={false}
                animationDuration={1000}
              />
              <Line
                type="monotone"
                dataKey="uv"
                stroke="#82ca9d"
                strokeWidth={2}
                dot={false}
                animationDuration={1000}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </section>

      {/* Feature Section */}
      <section className="features-section">
        <h2 className="section-title">Features that Empower</h2>
        <div className="features">
          <div className="feature-card">Real-time Analytics</div>
          <div className="feature-card">Custom Dashboards</div>
          <div className="feature-card">Detailed Reports</div>
          <div className="feature-card">24/7 Monitoring</div>
        </div>
      </section>

      {/* Call to Action Section */}
      <section className="cta-section">
        <h2 className="cta-title">Ready to get started?</h2>
        <button className="cta-button">Explore More</button>
      </section>

      <section>
      <Footer />
      </section>
    </div>
  );
}

export default HomePage;
