import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsIcon from '@mui/icons-material/Settings';
import ReportIcon from '@mui/icons-material/Report';

const Sidebar = ({ onLinkClick }) => {
  const location = useLocation();

  return (
    <List sx={{ paddingTop: '0px' }}> {/* Set the desired padding here */}
      <ListItem 
        button 
        component={Link} 
        to="/overview" 
        selected={location.pathname === '/overview'}
        onClick={onLinkClick} // Close drawer on link click
      >
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Overview" />
      </ListItem>

      <ListItem 
        button 
        component={Link} 
        to="/reports" 
        selected={location.pathname === '/reports'}
        onClick={onLinkClick} // Close drawer on link click
      >
        <ListItemIcon>
          <ReportIcon />
        </ListItemIcon>
        <ListItemText primary="Reports" />
      </ListItem>

      <ListItem 
        button 
        component={Link} 
        to="/settings" 
        selected={location.pathname === '/settings'}
        onClick={onLinkClick} // Close drawer on link click
      >
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="Settings" />
      </ListItem>
    </List>
  );
};

export default Sidebar;
