import React from 'react';
import { Typography, Paper, Box } from '@mui/material';

function Settings() {
  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Settings
      </Typography>
      <Paper elevation={3} sx={{ p: 2 }}>
        <Typography>Settings content goes here.</Typography>
      </Paper>
    </Box>
  );
}

export default Settings;
