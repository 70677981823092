import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

// Function to generate the next data point based on the current time
const generateNextDataPoint = (index) => {
  const hour = new Date();
  hour.setHours(hour.getHours() - (48 - index)); // Extend to past 48 hours

  // Create smooth and varied wave patterns
  const uv = Math.floor(2500 + Math.sin((index / 12) * 2 * Math.PI + Math.random() * 0.5) * 1000);
  const pv = Math.floor(2500 + Math.cos((index / 12) * 2 * Math.PI + Math.random() * 0.5) * 1000);

  return {
    name: hour.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
    uv,
    pv,
  };
};

function Overview() {
  // Generate initial data for the past 48 hours
  const [data, setData] = useState(Array.from({ length: 48 }, (_, i) => generateNextDataPoint(i)));

  useEffect(() => {
    const interval = setInterval(() => {
      setData((prevData) => {
        // Shift the data left and add a new data point on the right
        const newData = [...prevData.slice(1)];
        const nextIndex = prevData.length; // Index for the new data point
        newData.push(generateNextDataPoint(nextIndex));
        return newData;
      });
    }, 1000); // Update every second

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          dataKey="pv"
          stroke="#8884d8"
          strokeWidth={2}
          dot={false}
          animationDuration={1000}
        />
        <Line
          type="monotone"
          dataKey="uv"
          stroke="#82ca9d"
          strokeWidth={2}
          dot={false}
          animationDuration={1000}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}

export default Overview;
