import React, { useState } from 'react';
import { Button, Typography, Box, Paper } from '@mui/material';
import * as XLSX from 'xlsx';

function ExcelUpload() {
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState('');

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            setMessage(`Selected file: ${selectedFile.name}`);
        }
    };

    const handleUpload = async () => {
        if (!file) {
            setMessage('Please select an Excel file to upload.');
            return;
        }

        const reader = new FileReader();
        reader.onload = async (e) => {
            const binaryStr = e.target.result;
            const workbook = XLSX.read(binaryStr, { type: 'binary' });
            const sheetName = workbook.SheetNames[0]; // Assuming we want the first sheet
            const worksheet = workbook.Sheets[sheetName];
            const data = XLSX.utils.sheet_to_json(worksheet);

            // Send data to the backend
            try {
                const response = await fetch('https://api.yourdomain.com/upload-excel', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ data }), // Adjust based on your backend requirements
                });

                if (response.ok) {
                    setMessage('Data uploaded successfully!');
                } else {
                    setMessage('Error uploading data.');
                }
            } catch (error) {
                setMessage('Error uploading data: ' + error.message);
            }
        };
        
        reader.readAsBinaryString(file);
    };

    return (
        <Paper elevation={3} sx={{ p: 4, maxWidth: 600, mx: 'auto', mt: 4 }}>
            <Typography variant="h5" gutterBottom>
                Upload Excel Data
            </Typography>
            <Box sx={{ mb: 2 }}>
                <input type="file" accept=".xlsx, .xls" onChange={handleFileChange} />
            </Box>
            <Button variant="contained" color="primary" onClick={handleUpload}>
                Upload
            </Button>
            {message && (
                <Typography sx={{ mt: 2 }} color="textSecondary">
                    {message}
                </Typography>
            )}
        </Paper>
    );
}

export default ExcelUpload;
