import React from 'react';
import './HeroSection.css';

const HeroSection = () => {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <section className="hero-section">
      <h1 className="hero-title">Welcome to ININDIA Analytics</h1>
      <p className="hero-subtitle">Your journey starts with data-driven insights.</p>
      <div className="scroll-down" onClick={() => scrollToSection('chart-section')}>
        Scroll Down
      </div>
    </section>
  );
};

export default HeroSection;
