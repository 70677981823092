// src/components/PopupMenu.js
import React from 'react';
import './PopupMenu.css';

const PopupMenu = ({ isOpen, onClose }) => {
  // Smooth scroll function
  const handleScrollTo = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
      onClose(); // Close the menu after selecting
    }
  };

  return (
    <div className={`popup-menu ${isOpen ? 'open' : ''}`}>
      <button className="close-button" onClick={onClose}>X</button>
      <div className="waves" />
      <nav className="menu-items">
        <a href="#section1" onClick={() => handleScrollTo('section1')}>
          <i className="fas fa-chart-line"></i> Live Data Overview
        </a>
        <a href="#section2" onClick={() => handleScrollTo('section2')}>
          <i className="fas fa-chart-pie"></i> Analytics Insights
        </a>
        <a href="#section3" onClick={() => handleScrollTo('section3')}>
          <i className="fas fa-tachometer-alt"></i> Performance Metrics
        </a>
      </nav>
    </div>
  );
};

export default PopupMenu;
